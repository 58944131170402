import React from "react";

export default class PageNotFound extends React.Component {
  render() {
    return (
      <div className={'markdown'}>
        <h1>404 Страница не найдена</h1>
        <p>Данная страница не найдена.</p>
      </div>
    )
  }
}
