module.exports = {
  "title": "Зип",
  "path": "/equipment/zip/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./zip-01.jpg" alt="Зип" class="width-33 alignright"></p>
<p>Предлагаем к&nbsp;поставке под заказ оригинальные запасные части к
поставляемому промышленному оборудованию ( насосам, компрессорам,
клапанам, регуляторам, к&nbsp;установкам высокого давления и&nbsp;др.).</p>
<p>Опыт поставки крупных деталей для оборудования снятого с&nbsp;производства
позволяет значительно увеличить срок службы эксплуатируемого
оборудования.</p>
<p>ООО ПКФ &laquo;ПромХим-Сфера&raquo; гарантирует поставку оригинальных запасных
частей в&nbsp;сжатые сроки.</p>
`
}
