const ru = {
  page_title: 'Связь с нами',
  contacts: {
    title: 'Контактная информация',
    name: 'ООО ПКФ «ПромХим-Сфера»',
    address: ['Россия 123100 Набережная', 'Пресненская, дом 12, эт 45, ком.11, пом.IVВ'],
    phone: ['Тел/факс: ', '+7 495 644 46 33'],
    email: ['E-mail', 'info@ph-sfera.ru'],
    time: 'Звонки принимаются: 8:00-18:00'
  }
}

const en = {
  page_title: 'Contact us',
  contacts: {
    title: 'Contact information',
    name: 'JSC PKF "PromHim-Sfera"',
    address: ['Russia, 123100, Moscow', 'Nabereznaya Presnenskaya, 12, fl 45, room .11, section.IVВ'],
    phone: ['Tel/Fax: ', '+7 495 644 46 33'],
    email: ['E-mail', 'info@ph-sfera.ru'],
    time: 'Working hours: 8:00-18:00'
  }
}

const data = {ru, en}
export default data
