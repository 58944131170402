module.exports = {
  "title": "Мультифазные насосные станции",
  "path": "/equipment/nko/mps/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./multiphase-01.jpg" alt="Мультифазная насосная станция" class="width-33 alignright"></p>
<p>Необходимость заботы об&nbsp;окружающей среде, является важным фактором при
выборе МФНС, т.к. при использовании систем <strong>Rosscor</strong> отсутствует
необходимость в&nbsp;факелах по&nbsp;сжиганию попутного газа, а&nbsp;также значительно
снижается риск утечек.</p>
<p>Определенным плюсом применения МФНС является сокращение количества
необходимых сооружений и&nbsp;уменьшения стоимости строительства за&nbsp;счёт
отсутствия потребности в&nbsp;сепараторах, газовых компрессорах, резервуарных
емкостях и&nbsp;другого оборудования.</p>
<p>Применение мультифазных насосных станций <strong>Rosscor</strong> позволяет
перекачивать всю смесь из&nbsp;нефти, воды, газа и&nbsp;твёрдых частиц из&nbsp;куста
скважин на&nbsp;центральный пункт сбора через единственный трубопровод, что в
свою очередь существенно снижает капитальные расходы благодаря
уменьшению потребности в&nbsp;оборудовании в&nbsp;устье скважины.</p>
`
}
