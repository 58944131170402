import React  from "react";
import * as PropTypes from 'prop-types'

function PressItem({img, pdfLink, title, caption, description, last}) {
  return (
    <div className={'col one_fourth' + (last ? ' last' : '')}>
      <div className="tp-vl-textblock">
        <a href={pdfLink} target="_blank" rel="noreferrer">
          <img src={img} alt={title}/>
        </a>
        <p>
          <strong>{title}</strong>
          <br/>
          <em>{caption}</em>
        </p>
        <p>{description}</p>
      </div>
    </div>
  )
}

PressItem.propTypes = {
  img: PropTypes.string.isRequired,
  pdfLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  last: PropTypes.bool
}
PressItem.defaultProps = {}

export default PressItem
