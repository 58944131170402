import React from "react";
import * as PropTypes from "prop-types";
import Article from "../../components/Article";
import Helmet from "react-helmet";
import {config, pages} from "../../config";

function Equipment(props, context) {
  const article = pages.filter((item) => {
    return item.data.path === props.location.pathname && item.data.lang === context.lang
  })[0]
  
  return (
    <div>
      <Helmet
        title={article.data.title + ' / ' + config[context.lang]}
      />
      <Article title={article.data.title}>
        <div dangerouslySetInnerHTML={{__html: article.data.body}}/>
      </Article>
    </div>
  )
}

Equipment.contextTypes = {
  lang: PropTypes.string.isRequired
}

Equipment.propTypes = {
  // route: PropTypes.object.isRequired,
  // children: PropTypes.node
}
Equipment.defaultProps = {}

export default Equipment
