module.exports = {
  "title": "Multiphase pumping station",
  "path": "/equipment/nko/mps/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="./multiphase-01.jpg" alt="Multiphase pumping station" class="width-33 alignright"></p>
<p>Environmental care is&nbsp;important when choosing multiphase pumping station
(MPPS), because if&nbsp;you use <strong>Rosscor</strong> systems, there is&nbsp;no&nbsp;need for gas
flaring and also the risk of&nbsp;leakage reduces significantly.</p>
<p>The certain advantage of&nbsp;using MPPS is&nbsp;reduced number of&nbsp;required
buildings and reduced cost of&nbsp;construction, as&nbsp;there is&nbsp;no&nbsp;need in
separators, gas compressors, vessels and other equipment.</p>
<p>Usage of&nbsp;<strong>Rosscor</strong> multiphase pumping stations allows to&nbsp;pump entire
mixture of&nbsp;oil, water, gas and solid particles from the well cluster to
a&nbsp;central collection point through a&nbsp;single pipeline, which in&nbsp;its turn
significantly reduces the capital costs by&nbsp;reducing the amount of
required equipment at&nbsp;the wellhead.</p>
`
}
