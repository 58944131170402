module.exports = {
  "title": "Test facility",
  "path": "/equipment/test-stand/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="./test-stand-01.jpg" alt="Test facility" class="width-33 alignright"></p>
<p>JSC PKF &laquo;PromHim-Sfera&raquo; supplies facilities for valves testing,
including testing shutoff valves and safety valves. We&nbsp;also supply
various pipeline degreasing and washing facilities.</p>
<p>Shutoff valve testing is&nbsp;performed to&nbsp;measure its technical
characteristics, which in&nbsp;their turn determine the quality degree.
During the testing, such characteristics as&nbsp;leak tightness, durability
and other parameters are evaluated. For the hydraulic testing of&nbsp;valves
special facilities are used, which allow to&nbsp;determine the durability of
construction and the degree of&nbsp;its resistance to&nbsp;the internal pressure
of&nbsp;the operating medium.</p>
<p><strong>Technical features of&nbsp;the valves testing facilities</strong></p>
<p>Pipeline valves testing facilities perform testing in&nbsp;different modes:
automatic, manual step-by-step, adjustment control mode and also in&nbsp;the
mode of&nbsp;testing process registration in&nbsp;the pressure-time coordinates.</p>
<p>JSC PKF &laquo;PromHim-Sfera&raquo; selects and supplies testing facilities for
special demands, prescribed by&nbsp;our customers in&nbsp;questionnaires. We
select testing facility units according to&nbsp;the principles of&nbsp;safety and
performance efficiency.</p>
`
}
