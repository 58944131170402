module.exports = {
  "title": "Fire protection",
  "path": "/equipment/fire-protection/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="protect-03.png" alt="Fire protection" class="width-33 alignright"></p>
<p>Effective fire protection for:</p>
<ul>
<li>Electric, pneumatic and hydraulic drives</li>
<li>Blocking and control valves, ESDV</li>
<li>Joint flanges</li>
<li>Control and monitoring panels</li>
<li>Any other element of&nbsp;critical installation area</li>
<li>Can be&nbsp;installed without the need of&nbsp;unit operation stop;</li>
<li>Made to&nbsp;order, minimal size;</li>
<li>Resistance to&nbsp;the atmospheric impact, ultraviolet rays, cold, marine
environment, chemical reagents and acids;</li>
<li>Materials are safe and do&nbsp;not contain asbestos derivatives;</li>
<li>Resistance to&nbsp;fire impact for 2&nbsp;hours with temperature up&nbsp;to&nbsp;1400 ˚С.</li>
</ul>
<p>Fire protection guarantees full functioning of&nbsp;critical systems,
protected for the whole period of&nbsp;time, demanded by&nbsp;the client,
preventing further damaging of&nbsp;devices, products, things and people.</p>
<p>Fire protection equipment supplied by&nbsp;our company has all the necessary
certificates and licenses. The entire production stage, starting from
purchasing raw materials to&nbsp;packaging and shipping to&nbsp;the customers is
carried out under quality control and in&nbsp;accordance with ISO 9001-2008
standards certified by&nbsp;Bureau Veritas.</p>
<p><img src="protect-04.jpg" alt="Fire protection" class="width-33">
<img src="protect-01.jpg" alt="Fire protection" class="width-33">
<img src="protect-02.jpg" alt="Fire protection" class="width-33"></p>
`
}
