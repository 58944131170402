module.exports = {
  "title": "Spare parts",
  "path": "/equipment/zip/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="./zip-01.jpg" alt="Spare parts" class="width-33 alignright"></p>
<p>At&nbsp;customer&rsquo;s request, we&nbsp;can supply original spare parts for supplied
industrial equipment (pumps, compressors, valves, controllers, high
pressure units, etc.).</p>
<p>Experience in&nbsp;supply of&nbsp;major spare parts for equipment which is&nbsp;out of
production can significantly extend the service life of&nbsp;existing
equipment.</p>
<p>JSC PKF &laquo;PromHim-Sfera&raquo; guarantees the supply of&nbsp;original spare parts
within the shortest time.</p>
`
}
