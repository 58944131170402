module.exports = {
  "title": "Газодувки",
  "path": "/equipment/blower/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./gas-01.jpg" alt="Газодувки" class="width-33 alignright"></p>
<p>Высокое давление газа очень востребовано во&nbsp;многих отраслях
промышленности. Газодувки могут эксплуатироваться в&nbsp;любых условиях, даже
в&nbsp;самых тяжелых. Так, они находят активное применение при подаче газов в
нефтеперерабатывающей промышленности, при подаче технологического газа
при производстве стали, при откачке метана из&nbsp;угольных шахт, на
цементных и&nbsp;известковых заводах, в&nbsp;установках очистки сточных вод, для
пневматической транспортировки, аэрации воды, создания разряженного
воздуха или вакуума, сжигания воздуха.</p>
<p>ООО ПКФ &laquo;ПромХим-Сфера&raquo; предлагает оборудование для работы с&nbsp;воздухом и
практически с&nbsp;любыми технологическими газами&nbsp;&mdash; как с&nbsp;инертными, так с
агрессивными.</p>
<p><img src="./gas-02.jpg" alt="Газодувки" class="width-33 alignright"></p>
<p>Данное оборудование имеет существенные преимущества по&nbsp;сравнению с
аналогами:</p>
<ul>
<li>Высокий КПД в&nbsp;любом режиме работы;</li>
<li>Широкая номенклатура типовых воздуходувок;</li>
<li>Взрывобезопасность и&nbsp;ударостойкость;</li>
<li>Применение крыльчаток с&nbsp;аэродинамическими лопастями, обладающими
высокой эффективностью и&nbsp;низким уровнем шума;</li>
<li>Согласно требованиям заказчика можно подобрать антикоррозионные,
газонепроницаемые, огнеупорные конструкции;</li>
<li>Повышенная степень безопасности;</li>
<li>Долгий эксплуатационный период;</li>
<li>Безаварийная работа установки.</li>
</ul>
`
}
