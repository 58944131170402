module.exports = {
  "title": "Pipeline equipment",
  "path": "/equipment/fitting/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="./valve-01.jpg" alt="Fittings"></p>
<p>JSC PKF &laquo;PromHim-Sphere&raquo; specializes in&nbsp;supply of&nbsp;equipment elements for
the following industrial fields:</p>
<ul>
<li>Thermal and nuclear power</li>
<li>Chemistry and petrochemistry</li>
<li>Metal industry</li>
<li>Oil and Gas industry</li>
<li>Water supply</li>
</ul>
<p>Specific nature of&nbsp;work of&nbsp;these plants is&nbsp;connected with the
transportation of&nbsp;liquids.</p>
<p>We&nbsp;supply connecting, regulating, shutoff valves, pipes, pipe fittings
and other elements of&nbsp;process equipment and we&nbsp;strive to&nbsp;ensure that
your enterprise, being equipped with high quality equipment, always
provides your consumers the products up&nbsp;to&nbsp;the world standards.</p>
`
}
