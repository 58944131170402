module.exports = {
  "title": "Compressors",
  "path": "/equipment/nko/compressor/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="./comp-01.jpg" alt="Compressor" class="width-33 alignright"></p>
<p>Of&nbsp;the whole variety of&nbsp;compressor equipment, produced currently by&nbsp;the
world&rsquo;s leading manufacturers, JSC PKF &laquo;PromHim-Sfera&raquo; is&nbsp;ready to&nbsp;make
the selection of&nbsp;equipment, which will suit for the specific task of
supplying our customer with compressed gas. With an&nbsp;extensive base of
suppliers from Germany, France, Italy, USA, we&nbsp;have a&nbsp;possibility to
supply various types of&nbsp;compressors: piston-type and centrifugal,
helical, diaphragm, cammed and other types of&nbsp;compressors.</p>
<p>For optimal selection of&nbsp;the compressor and its drive, which provides
the most efficient compressed gas feed it&nbsp;is&nbsp;necessary to&nbsp;know at&nbsp;least
several basic parameters:</p>
<h3>Compressible gas composition</h3>
<p>Usually variations of&nbsp;compressible gas composition occur in&nbsp;a&nbsp;relatively
narrow range, which has no&nbsp;effect on&nbsp;the performance of&nbsp;the compressor,
however, in&nbsp;case of&nbsp;predictable significant changes of&nbsp;gas parameters it
is&nbsp;necessary to&nbsp;specify the limits of&nbsp;variations of&nbsp;the composition.</p>
<h3>Compressed gas pressure and the limits of&nbsp;its changes</h3>
<p>For reliable, efficient and safe operation of&nbsp;the compressor there must
be&nbsp;a&nbsp;precise definition of&nbsp;the pressure oscillation limits, because for
example a&nbsp;significant inlet pressure boost in&nbsp;the compressor with a
fixed pressure boost degree may negatively affect the performance of&nbsp;the
compressor.</p>
<h3>Gas flow rate and its oscillation limits</h3>
<p>Depending on&nbsp;the type of&nbsp;compressor there are various limits of
efficient and safe compressed gas flow rate control. It&nbsp;is&nbsp;also
necessary to&nbsp;know both maximum and minimum gas pressure on&nbsp;compressor
suction line, what will allow to&nbsp;select the compressor providing both
the ability to&nbsp;operate at&nbsp;the minimum pressure on&nbsp;the suction line and
the ability to&nbsp;operate safely at&nbsp;the maximum pressure.</p>
<h3>Presence of&nbsp;impurities in&nbsp;the inlet and compressed gas</h3>
<p>The impurities entering the suction line of&nbsp;the compressor, for example
such as&nbsp;drip moisture or&nbsp;other liquids and also dust have a&nbsp;negative
impact on&nbsp;the majority of&nbsp;compressors. Depending on&nbsp;the type of
compressor and also the requirements for the quality of&nbsp;the outlet air,
selection of&nbsp;the suction line separator may be&nbsp;required. Depending on
the requirements to&nbsp;the quality of&nbsp;the compressed gas, an&nbsp;outlet gas
treatment system may be&nbsp;selected, such as&nbsp;drying system, oil separator
or&nbsp;the system of&nbsp;catalytic decomposition of&nbsp;organic compounds
(compressor oil). Also the compressor type will be&nbsp;defined (dry
compression, oil-filled, water packed, etc.).</p>
<h3>Equipment operation mode</h3>
<p>Both the frequency and duration of&nbsp;equipment operation period are
affected by&nbsp;work shifts at&nbsp;the facility and special characteristics of
technological process. Therefore it&nbsp;is&nbsp;necessary to&nbsp;know exactly at
least number of&nbsp;equipment start-ups (per day, per month), minimum and
maximum required time, which compressor must operate until it&nbsp;stops.</p>
<h3>Service times</h3>
<p>Equipment operating mode, as&nbsp;well as&nbsp;facility operational mode, in
particular the periodicity and terms of&nbsp;scheduled maintenance
performing, usually determine the types of&nbsp;compressors that may be
considered for use at&nbsp;the facility.</p>
<h3>Preferable type of&nbsp;compressor</h3>
<p>Taking into account that the most complete filling of&nbsp;the questionnaire
allows to&nbsp;select the most efficient type of&nbsp;equipment which provides the
required reliability, the type of&nbsp;compressor should be&nbsp;specified for the
case where there was already some experience of&nbsp;operating compressors at
the facility. For example, if&nbsp;there are trained professionals for
service maintenance of&nbsp;piston, centrifugal or&nbsp;helical compressors. Also,
if, for example, the existing operational staff is&nbsp;too busy, it&nbsp;is
possible to&nbsp;specify the types of&nbsp;compressors that require a&nbsp;minimum
number of&nbsp;man-hours per year for its services.</p>
<h3>Compressor installation site specifications</h3>
<p>Depending on&nbsp;climate conditions and category of&nbsp;equipment location area
in&nbsp;respect to&nbsp;explosion hazard, the compressor type and allowable drive
type will be&nbsp;specified. Geophysical, including climate parameters, will
determine not only requirements to&nbsp;the lubrication system, cooling
system and compressor footing, but also the container design. Container
supply has a&nbsp;number of&nbsp;significant advantages along with disadvantages.
In&nbsp;this connection it&nbsp;is&nbsp;necessary to&nbsp;consider the requirements to&nbsp;the
installation area, climate, operation mode to&nbsp;make a&nbsp;grounded decision
on&nbsp;necessity and optimality of&nbsp;container supply.</p>
<h3>Compressor drive type</h3>
<p>Different types of&nbsp;drives can be&nbsp;used depending on&nbsp;available fuel and
energy resources (FER), requirements for compressor reliability and
frequency of&nbsp;service maintenance. For example, the most efficient drives
for compressors with a&nbsp;power capacity of&nbsp;300&nbsp;&mdash; 1000&nbsp;kW or&nbsp;more are gas
reciprocating engines. Based on&nbsp;the conditions of&nbsp;minimization of
service, the most preferable are the electric ones. For a&nbsp;number of
operating conditions the most optimal are gas-turbine drives or&nbsp;steam
turbines. For optimal selection of&nbsp;drive for a&nbsp;powerful compressor, it
is&nbsp;reqired to&nbsp;provide data on&nbsp;the reliability of&nbsp;power supply of&nbsp;the
facility, presence of&nbsp;fuel limits and plans for the enterprise
development within next 5&nbsp;years.</p>
<h3>Engineering</h3>
<p>Due to&nbsp;the specificity of&nbsp;the above-mentioned requirements for the
compressors installation, it&nbsp;is&nbsp;necessary to&nbsp;involve an&nbsp;organization,
which specialists have an&nbsp;experience in&nbsp;optimizing of&nbsp;compressor systems
and the compressed gas feed mains for the optimal choice of&nbsp;equipment,
which will be&nbsp;installed. This especially actual in&nbsp;case of&nbsp;equipment
installation at&nbsp;an&nbsp;existing facility, because some changes in&nbsp;the
technological chain are almost always made in&nbsp;the process of&nbsp;the
facility operation, which significantly change losses during
transportation, demanded quality, volumes and modes of&nbsp;compressed gases
consumption. Local changes made to&nbsp;the gas transmission main pipeline
are also has an&nbsp;influence to&nbsp;efficiency of&nbsp;compressed gas supply to&nbsp;the
consumers. It&nbsp;is&nbsp;necessary to&nbsp;take into account the equipment operation
modes, as&nbsp;well as&nbsp;to&nbsp;consider the possibility of&nbsp;their changes in&nbsp;order
to&nbsp;optimize the application of&nbsp;capacity of&nbsp;the compressor equipment.</p>
<p>JSC PKF &laquo;PromHim-Sfera&raquo; has specialized technical department, which
carries out consulting services concerning selection and replacement of
compressors, design execution and embedding of&nbsp;compressor equipment at
enterprises in&nbsp;the Russian Federation. The company also works on&nbsp;the
projects of&nbsp;modernization of&nbsp;compressor equipment and supply of
components for compressors.</p>
`
}
