import React  from "react";
import * as PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import logo_ru from "./logo-ru.svg";
import logo_en from "./logo-en.svg";

function Logo(props, context) {
  const logo = context.lang === 'ru' ? logo_ru : logo_en
  return (
    <div className="logo">
      <Link href="/" to="/">
        <img src={logo} alt={'logo'}/>
      </Link>
    </div>
  )
}

Logo.contextTypes = {
  lang: PropTypes.string.isRequired
}
Logo.propTypes = {}
Logo.defaultProps = {}

export default Logo
