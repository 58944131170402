module.exports = {
  "title": "Water treatment reagents",
  "path": "/equipment/reagents/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="image1.png" alt="Reagents for water treatment" class="aligncenter"></p>
<p>Technological solutions in&nbsp;the field of&nbsp;reagent treatment of&nbsp;cooling water are the key areas of&nbsp;activity of&nbsp;the Chemical Department of&nbsp;the JSC PKF “PromHim-Sfera”.</p>
<p>We&nbsp;are constantly developing optimal solutions for cooling water.We help our customers to&nbsp;improve the efficiency of&nbsp;heat exchange of&nbsp;technological devices and reduce operating costs at&nbsp;industrial facilities.</p>
<p>When dealing with the most important and vital environment in&nbsp;industrial facilities and on&nbsp;planet Earth, our Customers trust a&nbsp;reliable partner capable of&nbsp;safely managing their water processes. We&nbsp;offer high-quality reagents for industrial water supply that prevent problems related to&nbsp;scale, sludge, corrosion, microbiology, and Legionella. We&nbsp;develop solutions aimed at&nbsp;improving operational efficiency and reliability of&nbsp;technological equipment, reducing water consumption with a&nbsp;significant return on&nbsp;investment for the Customer. Customers&rsquo; technical specialists turn to&nbsp;us as&nbsp;technical experts who help them reduce operating costs and water costs, and also increase the profitability of&nbsp;investments due to&nbsp;the most effective reagent water treatment program, modern equipment and a&nbsp;high-tech engineering approach.</p>

<p><img src="image2.jpg " alt="Reagents for water treatment" class="aligncenter"></p>

<p><strong>Chemical control of&nbsp;sludge and scale formation, corrosion.</strong></p>

The formation of&nbsp;deposits and colloidal structures are the causes of&nbsp;many problems in&nbsp;heat exchange equipment. The control of&nbsp;sludge and scale formation improves the efficiency of&nbsp;heat removal, reducing fuel and energy costs. A&nbsp;properly selected reagent treatment program, which maximally prevents the processes of&nbsp;sludge and scale formation, helps to&nbsp;eliminate additional operating costs for unscheduled shutdowns, on-the-go cleaning, premature repairs/ replacement of&nbsp;expensive heat exchange equipment, lost profits&nbsp;/ reduced output.</p>

<p>
Our high-tech reagent treatment programs will help you change your operating costs:
<ul>
<li>reduce the consumption of&nbsp;make-up and waste water;</li>
<li>improve the efficiency of&nbsp;key heat exchangers and heat removal;</li>
<li>ensure optimal cooling;</li>
<li>reduce energy and fuel consumption</li>
<li>increase the duration of&nbsp;the inter-repair run;</li>
<li>Improve reliability and security.</li>
</ul>
</p>

<p><strong>Microbiological control. </strong></p>
<p>Water cooling systems, especially in&nbsp;oil refineries and mineral fertilizer plants, have a&nbsp;favorable environment for the growth of&nbsp;microbiological organisms in&nbsp;cooling water. Without chemical control, microbiological growth can initiate the development of&nbsp;corrosion, pollution and other operational efficiency problems that can lead to&nbsp;increased fuel and energy consumption, unplanned shutdowns, repairs and production losses. We&nbsp;offer a&nbsp;wide range of&nbsp;dispersants, biocides and activators to&nbsp;protect your cooling systems and heat exchange equipment.</p>
<p><img src="image3.png" alt="Reagents for water treatment" class="aligncenter"></p>
<p><img src="image4.png" alt="Reagents for water treatment" class="aligncenter"></p>
<p class="aligncenter">
<img src="image5.jpeg " alt="Reagents for water treatment" />
<img src="image6.png" alt="Reagents for water treatment" />
<img src="image7.png" alt="Reagents for water treatment" />
</p>
`
}
