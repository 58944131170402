module.exports = {
  "title": "Valves",
  "path": "/equipment/valve/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="valve-01.jpg" alt="Valves" class="width-20 alignright"></p>
<p>High pressure valves supplied by&nbsp;JSC PKF &laquo;PromHim-Sfera&raquo; thanks to
special technical solutions and set of&nbsp;proposed options optimized for
the climate conditions of&nbsp;the Far North are the most popular within oil
and gas and energy industry of&nbsp;the Russian Federation.</p>
<p><strong>Shutoff valves</strong></p>
<p>Shutoff valve is&nbsp;one of&nbsp;the most common types of&nbsp;pipeline valves. This
is&nbsp;a&nbsp;device, in&nbsp;which the shut-off or&nbsp;regulating element reciprocates
parallel to&nbsp;the axis of&nbsp;the operating medium flow.</p>
<p><strong>Return rotary valves</strong></p>
<p>Return rotary valves are designed for automatic prevention of&nbsp;the return
flow of&nbsp;the operating medium in&nbsp;pipelines. Return rotary valves are
subdivided into lifting and rotary. Lifting return valves have a
reciprocating disc. The return rotary valve is&nbsp;equipped with a&nbsp;trim,
which rotates around a&nbsp;horizontal axis, which is&nbsp;located higher than a
valve seat center.</p>
<p><strong>Cutoff valves</strong></p>
<p>Cutoff valves are the types of&nbsp;shutoff valves, the basic parameter of
which is&nbsp;their quick response. They are used in&nbsp;a&nbsp;system when it&nbsp;is
necessary to&nbsp;apply the valves which ensure the minimum possible opening
or&nbsp;closing time. For this purpose electric, pneumatic or&nbsp;electromagnetic
drives are installed on&nbsp;the valves.</p>
<p><strong>Safety valves</strong></p>
<p>Safety valve is&nbsp;a&nbsp;pipe fitting designed for protection from mechanical
breakdown of&nbsp;vessels and pipelines with excessive pressure by&nbsp;automatic
discharge of&nbsp;excess liquid, vaporized and gaseous medium from the
systems and vessels with excessive pressure at&nbsp;extreme pressure boost.</p>
<p><strong>Distribution valves</strong></p>
<p>Distribution valves are designed for directing the operating medium to
one of&nbsp;the two (or&nbsp;more) served pipelines. Distribution valves are most
frequently used to&nbsp;control pneumatic and hydraulic drives. They are also
used for air sampling from several chambers.</p>
<p><strong>Mixing valves</strong></p>
<p>Mixing valves are used for mixing different mediums such as&nbsp;cold and hot
water in&nbsp;appropriate proportions, maintaining some specific parameter at
constant degree (e.g. mixture temperature) or&nbsp;changing it&nbsp;according to
some required regulation.</p>
<p><strong>Electromagnetic valves</strong></p>
<p>Electromagnetic valves can be&nbsp;subdivided into two basic types: valves of
direct and indirect action. Electromagnetic valves of&nbsp;direct action
ensure opening and closing of&nbsp;valves by&nbsp;the core movement at
electromagnetic valve coil feeding. In&nbsp;case of&nbsp;indirect action
construction the coil feeding opens the pilot valve and the main valve
opening occurs under the influence of&nbsp;the medium pressure or&nbsp;its
compensation at&nbsp;minimum mechanical effort.</p>
`
}
