module.exports = {
  "title": "Арматура",
  "path": "/equipment/fitting/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./valve-01.jpg" alt="Арматура"></p>
<p>Компания ООО ПКФ &laquo;ПромХим-Сфера&raquo; специализируется на&nbsp;поставках
элементов оборудования следующих отраслей промышленности:</p>
<ul>
<li>Тепловая и&nbsp;атомная энергетика</li>
<li>химия и&nbsp;нефтехимия</li>
<li>металлургия</li>
<li>газовая и&nbsp;нефтяная промышленность</li>
<li>водоснабжение</li>
</ul>
<p>Специфика работы данных предприятий связана с&nbsp;транспортировкой
жидкостей.</p>
<p>Мы&nbsp;поставляем соединительные, регулирующие, запорные арматуры, трубы,
трубные фасонные части и&nbsp;другие элементы технологического оборудования и
стремимся к&nbsp;тому, чтобы Ваше предприятие, оснащенное высококачественным
оборудованием, всегда поставляло потребителю продукцию на&nbsp;уровне мировых
стандартов.</p>
`
}
