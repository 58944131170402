module.exports = {
  "title": "Огнезащита",
  "path": "/equipment/fire-protection/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./protect-03.png" alt="Огнезащита" class="width-33 alignright"></p>
<p>Эффективная защита от&nbsp;огня для:</p>
<ul>
<li>Электрических, пневматических и&nbsp;гидравлических приводов</li>
<li>Блокирующих и&nbsp;регулирующих клапанов, ESDV</li>
<li>Фланец соединений</li>
<li>Панели управления и&nbsp;контроля</li>
<li>Любого другого элемента критической зоны установки</li>
<li>Устанавливаются без необходимости остановки установки;</li>
<li>Выполненные по&nbsp;заказу, имеют минимальные размеры;</li>
<li>Выдерживают сопротивление атмосферным воздействиям, ультрафиолетовым
лучам, холоду, морской среде, химическим реагентам и&nbsp;кислотам;</li>
<li>Материалы, из&nbsp;которых они изготовлены не&nbsp;являются опасными и&nbsp;не
содержат производных асбеста;</li>
<li>Выдерживают сопротивление огню в&nbsp;течении 2&nbsp;часов до&nbsp;температуры
1400 ˚С.</li>
</ul>
<p>Защита гарантирует полное функционирование критических систем,
защищенных на&nbsp;весь период времени, запрошенный клиентом, под контролем
задерживая повреждения и&nbsp;предотвращения еще больший ущерб аппаратам,
изделиям, вещам и&nbsp;людям.</p>
<p>Поставляемая нами огнезащита имеет все необходимые сертификаты и
свидетельства, весь производственный этап, от&nbsp;закупок сырья до&nbsp;упаковки
и&nbsp;отправки клиентам выполняются в&nbsp;режиме контроля качества и
соответствии с&nbsp;нормативами ISO 9001-2008 сертифицированными Bureau
Veritas.</p>
<p><img src="./protect-04.jpg" alt="Огнезащита" class="width-33">
<img src="./protect-01.jpg" alt="Огнезащита" class="width-33">
<img src="./protect-02.jpg" alt="Огнезащита" class="width-33"></p>
`
}
