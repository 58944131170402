module.exports = {
  "title": "Компрессоры",
  "path": "/equipment/nko/compressor/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./comp-01.jpg" alt="Компрессор" class="width-33 alignright"></p>
<p>Из&nbsp;всего многообразия компрессорного оборудования, производимого в
настоящее время ведущими мировыми компаниями, ООО ПКФ &laquo;ПромХим-Сфера&raquo;
готова осуществляет подбор оборудования, которое будет оптимально
подходить для конкретной задачи по&nbsp;снабжению сжатым газом конкретного
потребителя. Обладая обширной базой поставщиков из&nbsp;Германии, Франции,
Италии, США, мы&nbsp;имеем возможность поставок различных типов компрессоров:
поршневых и&nbsp;центробежных, винтовых, мембранных, кулачковых, а&nbsp;также
компрессоров других типов.</p>
<p>Для оптимального подбора компрессора и&nbsp;его привода, обеспечивающего
наиболее эффективную подачу сжатого газа необходимо знать как минимум
ряд базовых параметров:</p>
<h3>Состав сжимаемого газа</h3>
<p>Обычно колебания состава сжимаемого газа происходят в&nbsp;относительно
небольших пределах, не&nbsp;оказывающих влияния на&nbsp;производительность
компрессора, однако, в&nbsp;случае прогнозируемых существенных изменений
параметров газа необходимо указание пределов изменений его состава.</p>
<h3>Давление сжимаемого газа и&nbsp;пределы его измерения</h3>
<p>Для надёжной, экономичной и&nbsp;безопасной эксплуатации компрессора
необходимо точное определение пределов колебаний давлений, т.к.
например, существенное превышение впускного давления в&nbsp;компрессор с
фиксированной степенью повышения давления может негативно сказаться на
работоспособности компрессора.</p>
<h3>Расход газа и&nbsp;пределы его колебаний</h3>
<p>В&nbsp;зависимости от&nbsp;типа компрессора варьируются пределы экономичного и
безопасного регулирования расхода компримируемого газа. При этом
необходимо знать как максимальное, так и&nbsp;минимальное давление газа на
всасе в&nbsp;компрессор, что позволит подобрать компрессор, обеспечивающий
как работоспособность при минимальном давлении на&nbsp;всасе, так и
безопасную эксплуатацию при максимальном давлении.</p>
<h3>Наличие примесей</h3>
<p>Примеси, поступающие на&nbsp;всас компрессора, например такие как капельная
влага или иные жидкости, а&nbsp;также пыль оказывают вредное воздействие на
большинство компрессоров. В&nbsp;зависимости от&nbsp;типа применяемого
компрессора, та&nbsp;также требований к&nbsp;качеству выходного воздуха может
потребоваться подбор сепаратора на&nbsp;всасе. В&nbsp;зависимости от&nbsp;требований к
качеству сжатого газа может подбираться система подготовки газа на
выпуске, например система осушки, масляный сепаратор или система
каталитического разложения органических соединений (компрессорного
масла). Также будет определён тип компрессора (сухого сжатия,
маслозаполненный, водокольцевой и&nbsp;т.д.).</p>
<h3>Режим работы оборудования</h3>
<p>На&nbsp;периодичность и&nbsp;продолжительность рабочих кампаний оборудования
влияет как сменность работы на&nbsp;предприятии, так и&nbsp;особенности
технологического процесса. В&nbsp;этой связи необходимо точно знать как
минимум число пусков оборудования (в&nbsp;сутки, в&nbsp;месяц), минимальное и
максимальное необходимое время, которое должен наработать компрессор до
останова.</p>
<h3>Периодичность сервиса</h3>
<p>Режим работы оборудования, а&nbsp;также режим эксплуатации предприятия, в
частности&nbsp;&mdash; периодичность и&nbsp;сроки проведения ППР зачастую определяют
типы компрессоров, которые могут рассматриваться для использования на
объекте.</p>
<h3>Предпочтительный тип компрессора</h3>
<p>Учитывая, что максимально полное заполнение опросного листа позволяет
подобрать наиболее экономичный тип оборудования, обеспечивающий
необходимую надёжность, то&nbsp;тип компрессора оптимально указать для
случая, когда уже имеется опыт эксплуатации некоторых компрессоров на
предприятии. Например, когда есть подготовленные специалисты для
обслуживания, поршневых, центробежных или винтовых компрессоров. Также,
если, например высокая загрузка существующего эксплуатационного
персонала, то&nbsp;можно задать типы компрессоров, требующие минимальное
количество человеко-часов в&nbsp;год для своего сервиса.</p>
<h3>Характеристики зоны установки компрессора</h3>
<p>В&nbsp;зависимости от&nbsp;климатических условий и&nbsp;категорийности зоны размещения
оборудования по&nbsp;взрывоопасности будет уточнён как тип компрессора, так и
допустимый тип привода. Геофизические, в&nbsp;том числе и&nbsp;климатические
параметры определят не&nbsp;только требования к&nbsp;системе смазки, охлаждения и
фундаменту компрессора, но&nbsp;также и&nbsp;исполнение контейнера. При этом
контейнерная поставка имеет как ряд существенных плюсов, так и&nbsp;минусов.
В&nbsp;этой связи необходимо взвесить требования к&nbsp;зоне установке, климату,
режиму эксплуатации, что&nbsp;бы принять обоснованно решение по&nbsp;необходимости
и&nbsp;оптимальности контейнерной поставки.</p>
<h3>Тип привода компрессора</h3>
<p>В&nbsp;зависимости от&nbsp;доступных топливно-энергетических ресурсов (ТЭР),
требований к&nbsp;надёжности компрессора и&nbsp;периодичности сервисного
обслуживания могут использоваться различные типы приводов. Например,
наиболее экономичными приводами для компрессоров, мощностью 300&nbsp;&mdash; 1000
кВт и&nbsp;более, являются газопоршневые двигатели. Исходя из&nbsp;условий
минимизации сервиса, наиболее предпочтительны электроприводы. Для ряда
условий эксплуатации оптимальны газотурбинные приводы или паровые
турбины. Для оптимального подбора привода мощного компрессора оптимально
предоставить данные по&nbsp;надёжности энергоснабжения предприятия, наличии
лимитов на&nbsp;топливо и&nbsp;планах развития предприятия на&nbsp;перспективу в
пределах 5-ти лет.</p>
<h3>Инжиниринг</h3>
<p>В&nbsp;связи со&nbsp;специфичностью вышеизложенных требований к&nbsp;установке
компрессоров, для наиболее оптимального выбора устанавливаемого
оборудования, необходимо привлечение организации, специалисты которой
имеют опыт по&nbsp;оптимизации компрессорных систем и&nbsp;магистралей подачи
сжатых газов. Это наиболее актуально при установке оборудования на
существующее предприятие, т.к. практически всегда, в&nbsp;ходе работы
предприятия производятся изменения технологической цепочки, существенно
изменяющие потери при транспортировке, необходимое качество, объёмы, и
режимы потребления сжатых газов. Также на&nbsp;эффективность подачи сжатых
газов потребителям зачатую влияют локальные изменения введённые в
газотранспортные магистрали. При этом необходимо учитывать режимы работы
оборудования, а&nbsp;также рассмотреть возможность их&nbsp;изменения с&nbsp;целью
оптимального использования установленной мощности компрессорного
оборудования.</p>
<p>В&nbsp;компании ООО ПКФ &laquo;Промхим-Сфера&raquo; имеется специализированных
технический департамент, осуществляющий консалтинг по&nbsp;вопросам подбора и
замены компрессоров, проектирование и&nbsp;внедрение компрессорного
оборудования на&nbsp;предприятиях РФ. Компания также работает по&nbsp;проектам
модернизации компрессорного оборудования и&nbsp;поставок комлектующих к
компрессорам.</p>
`
}
