module.exports = {
  "title": "Ventilation",
  "path": "/equipment/ventilation/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="./vent-03.png" alt="Ventilation" class="width-20 alignright"></p>
<p><strong>Premises ventilation</strong> is&nbsp;a&nbsp;constant replacement of&nbsp;an&nbsp;air exhausted
during the process of&nbsp;human life activity. Ventilation consists of&nbsp;air
removal through the exhaust air line system and fresh air inlet from the
outside using the same system but through the inlet channels. Sometimes
the ambient air is&nbsp;additionally treated by&nbsp;special filters to&nbsp;improve
its quality.</p>
<p>In&nbsp;the first place, ventilation of&nbsp;premises, both manufacturing and
industrial, is&nbsp;the way of&nbsp;maintaining a&nbsp;constant temperature and
humidity inside the premises. When a&nbsp;human is&nbsp;in&nbsp;the premises, his body
generates heat and humidity changes as&nbsp;well. That is&nbsp;why premises
ventilation must be&nbsp;of&nbsp;the highest quality. It&nbsp;is&nbsp;necessary to&nbsp;design a
ventilation system prior to&nbsp;its installation. Indeed, premises
ventilation will be&nbsp;ineffective without precision computation, which
includes taking into consideration all the parameters.</p>
<p><img src="./vent-01.png" alt="Ventilation" class="width-25 alignright"></p>
<p>Parameters affecting the premises ventilation design:</p>
<ul>
<li>Number of&nbsp;people in&nbsp;the premises,</li>
<li>Floor space,</li>
<li>Airspace,</li>
<li>Ceiling height,</li>
<li>Time which people spend in&nbsp;the premises,</li>
<li>Premise occupancy level,</li>
<li>Category of&nbsp;works performed in&nbsp;the premises,</li>
<li>Additional coefficients.</li>
</ul>
<p><strong>Manufacturing premises ventilation</strong> can be&nbsp;the same as&nbsp;<strong>industrial
premises ventilation</strong> in&nbsp;terms of&nbsp;design concept, since the
manufacturing and industrial premises may be&nbsp;of&nbsp;the same size. Even if
they are different in&nbsp;size, the system layout may remain the same. Only
the parameters affecting power calculation for the ventilation and its
accessories (fans, valves, line cross-section size) will change.</p>
<p><img src="./vent-02.png" alt="Ventilation" class="width-25 alignright"></p>
<p>JSC PKF &laquo;PromHim-Sfera&raquo; performs delivery of&nbsp;any type of&nbsp;the equipment
necessary for high-quality air conditioning and ventilation on&nbsp;the
enterprises of&nbsp;manufacturing or&nbsp;industrial purpose. We&nbsp;will also provide
you with industrial and manufacturing equipment for smoke exhaust
systems.</p>
<p>Our company performs ventilation projects for industrial and manufacturing
buildings. If&nbsp;you want to&nbsp;install a&nbsp;high quality ventilation in
industrial or&nbsp;manufacturing premises, please contact&nbsp;us at&nbsp;JSC PKF
&laquo;PromHim-Sfera&raquo;.</p>
`
}
