module.exports = {
  "title": "Испытательные стенды",
  "path": "/equipment/test-stand/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./test-stand-01.jpg" alt="Испытательный стенд" class="width-33 alignright"></p>
<p>Компания ООО ПКФ &laquo;ПромХим-Сфера&raquo; поставляет стенды для испытания
арматуры, в&nbsp;том числе испытания запорной арматуры, испытания
предохранительных клапанов, а&nbsp;также различные стенды для обезжиривания и
промывки трубопроводов.</p>
<p>Испытание запорной арматуры выполняется для оценки ее&nbsp;свойств, которые,
в&nbsp;свою очередь, определяют уровень качества. При тестировании
производится оценка таких показателей как герметичность, прочность,
параметры. Для гидравлических испытаний арматуры используют специальные
стенды, которые позволяют выявить прочность конструкции и&nbsp;степень ее
сопротивления внутреннему давлению рабочей среды.</p>
<p><strong>Технические особенности стендов для испытания арматуры</strong></p>
<p>Стенды для испытания трубопроводной арматуры проводят тестирование в
различных режимах: автоматическом, пошаговом ручном, режиме наладочного
управления, а&nbsp;также режиме регистрации испытательного процесса в
координатах &laquo;давление-время&raquo;.</p>
<p>ООО ПКФ &laquo;ПромХим-Сфера&raquo; осуществляет поставку и&nbsp;подбор испытательных
стендов ориентированных на&nbsp;специальные требования, изложенные нашими
клиентами в&nbsp;технических заданиях, подбираем установки на&nbsp;основе
принципов безопасности и&nbsp;эффективности работы.</p>
`
}
