module.exports = {
  "title": "Насосы",
  "path": "/equipment/nko/pump/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./pump-01.jpg" alt="Насос" class="width-33 alignright"></p>
<p>ООО ПКФ &laquo;Промхим-Сфера&raquo; поставляет широкий спектр насосного
оборудования: герметичные, погружные, для водоснабжения, для жидкостей с
посторонними включениями, для нефтепродуктов, МФНС, для химической
промышленности, насосы для поддержания пластового давления в&nbsp;скважине
(ППД), нефтяные магистральные насосы, насосы для энергетической
промышленности.</p>
<p>Среди производителей насосного оборудования, поставляемых нашей
компанией: KSB, STERLING SIHI, KAMAT, WILO, FLOWSERVE, SAER, АО
&laquo;МОЛДОВАХИДРОМАШ&raquo; и&nbsp;другие.</p>
<p>Кроме того, мы&nbsp;поставляем под заказ запасные части к&nbsp;предлагаемому нами
насосному оборудованию.</p>
<p>Предлагаемые насосы пользуются спросом благодаря высокому качеству,
надежности в&nbsp;эксплуатации, проверенной годами работы и&nbsp;умеренным ценам.
При наличии потребности на&nbsp;насосное оборудование и&nbsp;для правильного его
подбора,просим сообщить нам полные характеристики насоса, включая:
перекачиваемую среду, ее&nbsp;температуру, расход, напор, место установки,
специфические особенности установки, варианты исполнения двигателя.</p>
`
}
