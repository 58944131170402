const ru = {
  page_title: 'Пресса о нас',
  press: [
    {
      title: 'НЕФТЯНИК',
      caption: 'Выпуск №13 | Апрель 2010',
      description: 'Коррозия — под контролем',
    },
    {
      title: 'Территория Нефтегаз',
      caption: 'Выпуск №6 | Июнь 2013',
      description: 'Наиболее эффективные решения в области коррозионного мониторинга',
    },
    {
      title: 'Нефтяник Удмуртии',
      caption: 'Выпуск №21 | Август 2013',
      description: 'Microcor: остановить коррозию',
    },
    {
      title: 'Инженерная практика',
      caption: 'Выпуск №8 | Август 2011',
      description: 'Опыт применения антикоррозионной защиты и эксплуатации системы коррозионного мониторинга',
    },
    {
      title: 'Oil&Gas Eurasia',
      caption: 'Выпуск №5 | Май 2014',
      description: 'Новые технологии перекачки продукции скважин',
    },
    {
      title: 'Oil&Gas Eurasia',
      caption: 'Выпуск №6 | Июнь 2013',
      description: 'Мониторинг коррозии Rohrback Cosasco Systems',
    },
    {
      title: 'Oil&Gas Eurasia',
      caption: 'Выпуск №6 | Июнь 2011',
      description: 'Наши эффективные решения для борьбы с коррозией',
    },
    {
      title: 'Oil&Gas Eurasia',
      caption: 'Выпуск №6 | Июнь 2009',
      description: 'Мониторинг коррозии в системах высокого давления',
    },
  ],
}

const en = {
  page_title: 'Media about us',
  press: [
    {
      title: 'NEFTIANIK',
      caption: 'Issue №13 | April 2010',
      description: 'Corrosion is under control',
    },
    {
      title: 'Territoria Neftegaz',
      caption: 'Issue №6 | June 2013',
      description: 'The most effective corrosion monitoring solutions',
    },
    {
      title: 'Neftianik Udmurtii',
      caption: 'Issue №21 | August 2013',
      description: 'Microcor: to stop corrosion',
    },
    {
      title: 'Inzhenernaya praktika',
      caption: 'Issue №8 | August 2011',
      description: 'Experience of using anti-corrosion protection and operating corrosion monitoring systems',
    },
    {
      title: 'Oil&Gas Eurasia',
      caption: 'Issue №5 | May 2014',
      description: 'New Technology for Pumping Wellbore Fluids',
    },
    {
      title: 'Oil&Gas Eurasia',
      caption: 'Issue №6 | June 2013',
      description: 'Rohrback Cosasco Corrosion Monitoring Systems',
    },
    {
      title: 'Oil&Gas Eurasia',
      caption: 'Issue №6 | June 2011',
      description: 'Our effective solutions for corrosion protection',
    },
    {
      title: 'Oil&Gas Eurasia',
      caption: 'Issue №6 | June 2009',
      description: 'Corrosion monitoring in the high pressure systems',
    }
  ],
}

const data = {ru, en}
export default data
