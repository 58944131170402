import './css/styles.css';
import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import Page from "./components/Page";
import Index from "./pages/index";
import Press from "./pages/press/index";
import Contacts from "./pages/contacts";
import Clients from "./pages/clients";
import PageNotFound from "./pages/404";
import Equipment from "./pages/equipment/_template";
import * as config from "./config";

config.pages.map((item) => {
  if (item.requirePath && item.file && item.file.name.length === 2) {
    item.data = require('./pages/' + item.requirePath);
  }
  return item;
});

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Page>
          <Switch>
            <Route exact path="/" component={Index}/>
            <Route path="/contacts" component={Contacts}/>
            <Route path="/clients" component={Clients}/>
  
            <Route path="/equipment/corrosion-monitoring/" component={Equipment}/>
            <Route path="/equipment/tube-cleaning/" component={Equipment}/>
            <Route path="/equipment/ventilation/" component={Equipment}/>
            {/*<Route path="/equipment/valve/" component={Equipment}/>*/}
            {/*<Route path="/equipment/blower/" component={Equipment}/>*/}
            {/*<Route path="/equipment/fire-protection/" component={Equipment}/>*/}
            <Route path="/equipment/zip/" component={Equipment}/>
            {/*<Route path="/equipment/test-stand/" component={Equipment}/>*/}
            {/*<Route path="/equipment/nko/pump/" component={Equipment}/>*/}
            {/*<Route path="/equipment/nko/compressor/" component={Equipment}/>*/}
            {/*<Route path="/equipment/nko/mps/" component={Equipment}/>*/}
            <Route path="/equipment/fitting/" component={Equipment}/>
            <Route path="/equipment/reagents/" component={Equipment}/>
            <Redirect exact from="/equipment" to="/"/>
            
            <Route path="/press" component={Press}/>
            <Route path="/" component={PageNotFound}/>
            <Route path="*" component={PageNotFound}/>
          </Switch>
        </Page>
      </Router>
    );
  }
}
