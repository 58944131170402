module.exports = {
  "title": "Вентиляция",
  "path": "/equipment/ventilation/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
 <p><img src="./vent-03.png" alt="Вентиляция" class="width-20 alignright"></p>
<p><strong>Вентиляция помещения</strong>&nbsp;&mdash; это постоянная замена отработанного в
процессе жизнедеятельности человека воздуха. Вентиляция происходит за
счёт удаления воздуха через систему вытяжных магистралей и&nbsp;забором
свежего воздуха с&nbsp;улицы через туже систему, но&nbsp;через впускные каналы
магистрали. Иногда воздух с&nbsp;улицы дополнительно обрабатывается
специальными очистными фильтрами для улучшения его качества.</p>
<p>Вентиляция помещений, не&nbsp;важно, производственных или промышленных, это
прежде всего способ поддержания постоянной температуры и&nbsp;влажности
внутри самого помещения. Ведь в&nbsp;процессе нахождения человека в
помещении, выделяется тепло от&nbsp;его тела, а&nbsp;также изменяется влажноть
воздуха. Именно для этого необходима качественная вентиляция помещения.
Чтобы установить систему вентиляции помещения, необходимо сначала её
спроектировать. Ведь без точных расчётов, в&nbsp;которые входит учёт всех
показателей, вентиляция помещения будет неэффективна.</p>
<p><img src="./vent-01.png" alt="Вентиляция" class="width-25 alignright"></p>
<p>Показатели влияющие на&nbsp;проект вентиляции помещения:</p>
<ul>
<li>количество находящихся людей в&nbsp;помещении,</li>
<li>площадь помещения,</li>
<li>объем помещения,</li>
<li>высота потолков,</li>
<li>время нахождения людей в&nbsp;помещении,</li>
<li>уровень загруженности помещения,</li>
<li>категория работ в&nbsp;помещении,</li>
<li>дополнительные коэффициенты.</li>
</ul>
<p><strong>Вентиляция промышленных помещений</strong> может не&nbsp;отличатся от&nbsp;<strong>вентиляции
производственных помещений</strong> по&nbsp;схемам проектирования. Так как
промышленное и&nbsp;производственное помещения могут быть одинаковыми по
своим размерам. Даже если они будут отличаться по&nbsp;размерам, схема
прокладки системы может не&nbsp;измениться. Изменятся лишь показатели,
влияющие на&nbsp;расчёт мощности самой вентиляции и&nbsp;её&nbsp;агрегатов
(вентиляторов, клапанов, размеры сечения магистрали).</p>
<p><img src="./vent-02.png" alt="Вентиляция" class="width-25 alignright"></p>
<p>Компания ООО ПКФ &laquo;ПромХим-Сфера&raquo; занимается поставками любого
оборудования, которое необходимо для качественного кондиционирования и
вентиляции воздуха на&nbsp;предприятиях промышленного и&nbsp;производственного
назначения. Также мы&nbsp;обеспечим вас промышленным и&nbsp;производственным
оборудованием для систем дымоудаления.</p>
<p>Наша компания занимается вентиляцией для промышленных и&nbsp;производственных
зданий. Если вы&nbsp;хотите установить качественную вентиляцию в&nbsp;промышленном
или производственном помещении, обращайтесь к&nbsp;нам в&nbsp;ООО ПКФ
&laquo;ПромХим-Сфера&raquo;.</p>
`
}
