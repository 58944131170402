module.exports = {
  "title": "Очистные устройства для трубопроводов",
  "path": "/equipment/tube-cleaning/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<h2>Скребки и&nbsp;поршни</h2>
<div class="col one_third">
<ul>
<li>Обходной скребок</li>
<li>Спасательный скребок</li>
<li>Скребок для удаления парафина</li>
<li>Скребки магнитной очистки</li>
<li>Щеточный скребок</li>
<li>Двухмодульные скребки</li>
<li>Игольчатый скребок для удаления твердых отложений</li>
<li>Скребок для удаления жестких отложений</li>
<li>Скребки с&nbsp;двойным/многоступенчатым диаметром</li>
</ul>
</div>
<div class="col one_third">
<ul>
<li>Аппаратура слежения магнитных скребков</li>
<li>Акустическое контрольное оборудование</li>
<li>Запасные части</li>
<li>Стандартные скребки</li>
<li>Пенополиуретановые скребки</li>
<li>Скребки высокого уплотнения</li>
<li>Индикаторы прохождения скребка</li>
<li>Шаровые поршни</li>
<li>Герметизаторы трубы</li>
<li>Тестеры сварных швов</li>
<li>Длинные износостойкие скребки</li>
</ul>
</div>
<div class="col one_third last">
<img src="./pig-01.png" alt="Очистное утройство для трубопроводов" />
</div>
<h2>Аппаратура слежения магнитных скребков</h2>
<p>Наземное оборудование слежения магнитных скребков&nbsp;&mdash; очень надежная
система, созданная специально для работы в&nbsp;поле. Нынешняя модель
является результатом постоянного развития оборудования, возникшего в
1979&nbsp;году. Это оборудование работает на&nbsp;принципе определения изменения
магнитного поля при проходе скребка мимо датчика. Большинство типов
скребков, включая шаровые и&nbsp;эховые/пенные скребки, могут быть найдены,
если они снабжены маленькими магнитами. Следящее оборудование магнитных
скребков&nbsp;&mdash; специальная переносная система с&nbsp;питанием 12В постоянного
тока, которое можно получить от&nbsp;грузовика или небольшого аккумулятора.</p>
<h2>Акустическое контрольное оборудование</h2>
<p>Эта система слежения за&nbsp;скребком позволяет оператору прослушивать
скребок при его проходе по&nbsp;трубопроводу. Эта система работает от
датчика, получающего низкоэнергетические импульсы от&nbsp;стенок труб и
усиливающего сигнал через блок управления для выдачи звука в&nbsp;наушники.
Импульсы возникают, когда чашки или диски скребка сталкиваются с
внутренними сварными швами между трубами и&nbsp;фитингами. Акустическое
контрольное оборудование полностью портативное и&nbsp;является идеальным
дополнением к&nbsp;наземному следящему магнитному оборудованию.</p>
`
}
