module.exports = {
  "title": "Pipelines treating facility",
  "path": "/equipment/tube-cleaning/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<h2>Pigs and pistons</h2>
<div class="col one_third">
<ul>
<li>Bypass pig</li>
<li>Rescue pig</li>
<li>Paraffin removal pig</li>
<li>Magnetic cleaning pig</li>
<li>Brush pig</li>
<li>Dual module pig</li>
<li>Needle pig for solid deposits removal</li>
<li>Pig for hard deposits removal</li>
<li>Magnetic pigs monitoring equipment</li>
<li>Acoustic monitoring equipment</li>
</ul>
</div>
<div class="col one_third">
<ul>
<li>Spare parts</li>
<li>Standard pigs</li>
<li>Polyurethane foam pigs</li>
<li>High seal pigs</li>
<li>Pig passage indicators</li>
<li>Ball bearing piston</li>
<li>Pipeline sealers</li>
<li>Weld seams testers</li>
<li>Long wear-resistant pig</li>
</ul>
</div>
<div class="col one_third last">
<img src="./pig-01.png" />
</div>
<h2>Magnetic pigs monitoring equipment</h2>
<p>Ground magnetic pigs monitoring equipment is&nbsp;a&nbsp;very reliable system
created especially for field works. The current model is&nbsp;the result of
constant development of&nbsp;equipment, which was created in&nbsp;1979. This
equipment operates on&nbsp;the principle of&nbsp;identification of&nbsp;the magnetic
field change when a&nbsp;pig passes the sensor. Most types of&nbsp;pigs, including
ball and echo/foam pigs, can be&nbsp;found, if&nbsp;equipped with small magnets.
Magnetic pigs tracking equipment is&nbsp;a&nbsp;special portable system powered by
12V&nbsp;DC, which can be&nbsp;obtained from a&nbsp;truck or&nbsp;a&nbsp;small battery.</p>
<h2>Acoustic monitoring equipment</h2>
<p>This pig tracking system allows an&nbsp;operator to&nbsp;hear the pig as&nbsp;it&nbsp;passes
a&nbsp;pipeline. This system is&nbsp;powered by&nbsp;the sensor which receives
low-energy pulses from the pipeline walls and amplifies the signal
through the control unit to&nbsp;pass a&nbsp;sound to&nbsp;the headphones. The pulses
occur when the pig cups or&nbsp;discs collide with the internal weld seams
between pipes and fittings. Acoustic monitoring equipment is&nbsp;fully
portable and is&nbsp;an&nbsp;ideal addition to&nbsp;the ground magnetic tracking
equipment.</p>
`
}
