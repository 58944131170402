module.exports = {
  "title": "Corrosion monitoring",
  "path": "/equipment/corrosion-monitoring/",
  "description": null,
  "keywords": [],
  "lang": "en",
  "body": `
<p><img src="./image1.png" alt="Corrosion monitoring" class="width-20 alignright"></p>
<p>JSC PKF “PromHim-Sfera” offers a wide range of products manufactured by <strong>Cosasco</strong> for corrosion control by various methods: gravimetric, electrical resistance, linear polarization and ultrasonic method.</p>

<ul>
<li>The main factor in choosing corrosion monitoring equipment is its wide range of applications: from monitoring the actual wall thickness of the pipeline to identifying factors affecting corrosion processes.</li>
<li>Industrial application of the proposed systems allows you to quickly respond to any changes in the technological process, reducing corrosion risks.</li>
</ul>

<p><img src="./image2.png" alt="Corrosion monitoring" class="width-20 alignright">
The introduction of modern corrosion control systems integrated into the production process control system (ACS) significantly increases the efficiency of monitoring systems.</li>
</p>

<p>JSC PKF “PromHim-Sfera” develops and manufactures corrosion monitoring equipment on the territory of the Russian Federation and is ready to offer modern technological solutions for its Customers.</p>
<p><img src="./image3.png" alt="Corrosion monitoring" class="aligncenter"></p>
`
}
