module.exports = {
  "title": "Клапаны",
  "path": "/equipment/valve/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./valve-01.jpg" alt="Клапаны" class="width-20 alignright"></p>
<p>Клапаны высокого давления поставляемые ООО ПКФ &laquo;ПромХим-Сфера&raquo;
благодаря специальным техническим решениям и&nbsp;набором предлагаемых опций,
оптимизированным для климатических условий крайнего севера, наиболее
популярны в&nbsp;нефтегазовом и&nbsp;энергетическом комплексах Российской
Федерации.</p>
<p><strong>Клапаны запорные</strong></p>
<p>Запорный клапан&nbsp;&mdash; один из&nbsp;распространенных видов трубопроводной
арматуры. Это устройство, в&nbsp;котором запорный или регулирующий орган
перемещается возвратно-поступательно параллельно оси потока рабочей
среды. Одно из&nbsp;распространенных названий запорного клапана&nbsp;&mdash; вентиль,
но&nbsp;на&nbsp;самом деле по&nbsp;ГОСТ 24856-81 применение синонима &laquo;вентиль&raquo;&nbsp;&mdash; не
рекомендуется.</p>
<p><strong>Клапаны обратные поворотные</strong></p>
<p>Клапаны обратные поворотные предназначены для автоматического
предотвращения обратного потока рабочей среды в&nbsp;трубопроводах. Клапан
обратный поворотный подразделяется на&nbsp;подъёмный и&nbsp;поворотный. Подъёмные
обратные клапаны имеют диск, совершающий обратно-поступательные
движения. Клапан обратный поворотный снабжен затвором, который
поворачивается вокруг горизонтальной оси, расположенной выше центра
седла клапана.</p>
<p><strong>Клапаны отсечные</strong></p>
<p>Клапаны отсечные&nbsp;&mdash; вид клапанов запорных, основной характеристикой
которых является быстродействие при срабатывании. Их&nbsp;используют, когда в
системе необходимо применить арматуру, обеспечивающую минимально
возможное время при открытии или закрытии. Для этих целей на&nbsp;клапана
устанавливают электро-, пневматические или электромагнитные привода.</p>
<p><strong>Клапаны предохранительные</strong></p>
<p>Предохранительный клапан&nbsp;&mdash; трубопроводная арматура, предназначенная
для защиты от&nbsp;механического разрушения сосудов и&nbsp;трубопроводов с
избыточным давлением, путем автоматического выпуска избытка жидкой,
паро- и&nbsp;газообразной среды из&nbsp;систем и&nbsp;сосудов с&nbsp;избыточным давлением
при чрезмерном повышении давления.</p>
<p><strong>Клапаны распределительные</strong></p>
<p>Распределительные клапаны предназначены для направления рабочей среды в
один из&nbsp;двух (или больше) обслуживаемых трубопроводов. Наиболее часто
распределительный клапан используется для управления пневмоприводами и
гидроприводами. Он&nbsp;используется также для отбора проб воздуха из
нескольких камер.</p>
<p><strong>Клапаны смесительные</strong></p>
<p>Смесительные клапаны используется для смешивания в&nbsp;соответствующих
пропорциях различных сред, например холодную и&nbsp;горячую воду, выдерживая
постоянным определенный параметр (например, температуру смеси) или
изменяя его по&nbsp;требуемому закону.</p>
<p><strong>Клапаны электромагнитные</strong></p>
<p>Электромагнитные клапаны можно разделить на&nbsp;два основных типа: прямого и
не&nbsp;прямого действия. Электромагнитные клапаны прямого действия
обеспечивают открытие или закрытие клапанов за&nbsp;счет движения сердечника
при запитывании катушки электромагнитного клапана. В&nbsp;конструкции
непрямого действия запитывания катушки открывает пилотный клапан, а
открытие основного клапана уже происходит при воздействии давления среды
или его компенсации при минимальных механических усилий.</p>
`
}
