module.exports = {
  "title": "Мониторинг коррозии",
  "path": "/equipment/corrosion-monitoring/",
  "description": null,
  "keywords": [],
  "lang": "ru",
  "body": `
<p><img src="./image1.png" alt="Мониторинг коррозии" class="width-20 alignright"></p>
<p>Компания ООО ПКФ &laquo;ПромХим-Сфера&raquo; предлагает широкий ряд изделий производства <strong>Cosasco</strong> для контроля коррозии различными методами: гравиметрический, электрического сопротивления, линейной поляризации и&nbsp;ультразвуковой метод.</p>

<ul>
<li>Главным фактором при выборе оборудования мониторинга коррозии является его широкий спектр применения: от&nbsp;наблюдения за&nbsp;реальной толщиной стенки трубопровода до&nbsp;выявления факторов влияющих на&nbsp;коррозионные процессы.</li>
<li>Промышленное применение предлагаемых систем позволяет оперативно реагировать на&nbsp;любые изменения в&nbsp;технологическом процессе, снижая коррозионные риски.</li>
</ul>

<p><img src="./image2.png" alt="Мониторинг коррозии" class="width-20 alignright">
Внедрение современных систем контроля коррозии, интегрированных в&nbsp;систему управления технологическими процессами производства (АСУ&nbsp;ТП) существенно повышает эффективность систем мониторинга.</li>
</p>

<p>Компания ООО ПКФ &laquo;ПромХим-Сфера&raquo; ведёт разработку, осуществляет производство оборудования мониторинга коррозии на&nbsp;территории Российской Федерации и&nbsp;готова предложить современные технологичные решения для своих Заказчиков.</p>
<p><img src="./image3.png" alt="Мониторинг коррозии" class=" aligncenter"></p>
`
}
