const ru = {
  footer_menu: {
    press: {
      title: 'Пресса о нас',
      path: '/press/'
    },
    contacts: {
      title: 'Контакты',
      path: '/contacts/'
    }
  },
  copyright: 'ООО ПКФ "ПромХим-Сфера" © ',
}

const en = {
  footer_menu: {
    press: {
      title: 'Media about us',
      path: '/press/'
    },
    contacts: {
      title: 'Contacts',
      path: '/contacts/'
    }
  },
  copyright: 'JSC PKF "PromHim-Sfera" © ',
}

const data = {ru, en}
export default data
